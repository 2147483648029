/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import Modal from './Modal';
import { getTokenAccountInformation } from '../../helpers/token.helper';
import { useFetchCompanyAttachments } from '../../modules/admin/company/hooks';
import { ApiCompany } from '../../modules/admin/company/api';
import { ToastNotify } from '../Toast/toast';
import IconDictionary from '../Icons/icons';
import DeleteModal from '../Delete/deleteModal';
import { attachment } from '../Register/attachments';
import Input from '../Input/input';
import Button from '../Button/button';
import maskHelper from '../../helpers/mask.helper';

interface props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setState: (e: boolean) => void;
  insertImage: (e: string, width: string, heigth: string) => Promise<void>;
}

function isImage(filename: string) {
  const accepedFormats = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'svg',
    'tiff',
  ];

  const format = filename.split('.').pop()?.toLowerCase();

  // Verifica se a extensão está na lista de formatos de imagem
  return accepedFormats.includes(format ?? '');
}

function imageToBase64(imgElement: any) {
  const canvas = document.createElement('canvas');
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;

  const context = canvas.getContext('2d');
  context?.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);

  const base64String = canvas.toDataURL('image/png');
  return {
    base64: base64String,
    width: String(imgElement.width),
    heigth: String(imgElement.height),
  };
}

const ModalUploadImageCompanie: React.FC<props> = ({
  setShow,
  show,
  setState,
  insertImage,
}) => {
  const { notify } = ToastNotify();
  const companieId = getTokenAccountInformation().companie_id;
  const [key, setKey] = useState('');
  const [file, setFile] = useState<attachment>();
  const { attachmentsList } = useFetchCompanyAttachments(key, companieId);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDimensions, setModalDimension] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({
    width: '',
    heigth: '',
    url: '',
  });

  const createFile = async (fileForm: any) => {
    setState(true);
    const formData: any = new FormData();

    try {
      formData.append('companie_id', companieId);
      formData.append('title', fileForm[0].name);
      formData.append('file', fileForm[0]);
    } catch {
      setState(false);
    }

    const res = await ApiCompany.createCompanyAttachments(formData);
    if (res.created_at) {
      notify({ message: 'Arquivo enviado com sucesso', type: 'Success' });
      setKey(`${Math.random()}newfile`);
      setState(false);
    } else {
      notify({ message: 'Desculpe, algo deu errado', type: 'Error' });
      setState(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    maxSize: 1048576,
    accept: { 'image/*': [] },
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      if (acceptedFiles.length !== 0) {
        createFile(fileCreateObj);
      } else {
        notify({
          message:
            'Arquivo inválido. Somente imagens com tamanho máximo de 1 MB são aceitas.',
          type: 'Warning',
        });
      }
    },
  });

  return (
    <Modal setIsOpen={setShow} title="Imagens" size="6xlarge" isOpen={show}>
      <div
        {...getRootProps()}
        className={classNames(
          'border-[1px] border-dashed border-[#aaa] p-6 rounded-md cursor-pointer bg-background mb-4 relative',
          {
            'border-[#DC7E19]': isDragActive,
          }
        )}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="font-light text-sm text-primary text-center">
            Solte o arquivo aqui...
          </p>
        ) : (
          <p className="font-light text-sm text-center text-[#555]">
            Arraste e solte aqui ou clique para selecionar um arquivo
          </p>
        )}
      </div>
      <div className="grid grid-cols-4 gap-2 items-center">
        {attachmentsList
          .filter((item) => isImage(item.title))
          .map((elem) => (
            <div className="relative">
              <IconDictionary
                name="Excluir"
                size={20}
                className="cursor-pointer hover:scale-110 absolute right-1 top-1 text-red padding-1 bg-white rounded-full "
                onClick={() => {
                  setFile(elem);
                  setModalDelete(true);
                }}
              />
              <button
                type="button"
                onClick={async () => {
                  const imgElement = document.getElementById(
                    `img_companie_${String(elem.id)}`
                  );
                  const base64String = imageToBase64(imgElement);
                  setImgDimensions({
                    width: base64String.width,
                    heigth: base64String.heigth,
                    url: base64String.base64,
                  });
                  setModalDimension(true);
                }}
              >
                <img
                  id={`img_companie_${String(elem.id)}`}
                  className="max-w-full h-auto"
                  src={`${process.env.REACT_APP_API}${elem.path}`}
                  // https://api.plus.qa.leadsim.com.br/
                  alt={elem.title}
                  crossOrigin="anonymous"
                />
              </button>
            </div>
          ))}
      </div>
      <DeleteModal
        updateTable={() => setKey(`${Math.random()} delete`)}
        apiFunction={ApiCompany.deleteAttachments}
        name={file?.title ?? ''}
        type="Arquivo"
        close={() => setModalDelete(false)}
        id={file?.id ?? -1}
        notify={notify}
        open={modalDelete}
      />
      <Modal
        minHeight="200px"
        isOpen={modalDimensions}
        setIsOpen={setModalDimension}
        title="Dimensões da Imagem"
      >
        <div className="flex flex-col gap-3">
          <Input
            variant="outline-orange"
            title="Largura"
            icon="px"
            value={imgDimensions.width}
            onChange={(e) =>
              setImgDimensions({
                ...imgDimensions,
                width: maskHelper.number(e.target.value),
              })
            }
          />
          <Input
            variant="outline-orange"
            title="Altura"
            icon="px"
            value={imgDimensions.heigth}
            onChange={(e) =>
              setImgDimensions({
                ...imgDimensions,
                heigth: maskHelper.number(e.target.value),
              })
            }
          />
          <Button
            className="mx-auto mt-5"
            actionType="button-loading"
            onClick={async () => {
              await insertImage(
                imgDimensions.url,
                `${String(imgDimensions.width)}px`,
                `${String(imgDimensions.heigth)}px`
              );
              setModalDimension(false);
              setShow(false);
            }}
          >
            Inserir
          </Button>
        </div>
      </Modal>
    </Modal>
  );
};

export default ModalUploadImageCompanie;
