import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, Input } from '../../../components';

const schema = Yup.object({
  file: Yup.mixed().required(),
  password: Yup.string().required(),
});
interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues: Record<string, any>;
  onBack?: () => void;
}

const ServiceInvoiceAccessData: FC<FormProps> = ({
  defaultValues,
  onSubmit,
  onBack,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/x-pkcs12': ['.pfx', '.txt'],
    },
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      setValue('file', acceptedFiles?.[0]);
    },
  });

  const file = watch('file');

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Dados de acesso</h2>
        <div className="flex flex-col items-center w-full mt-7">
          <div
            {...getRootProps()}
            className={classNames(
              'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative w-full h-24 flex items-center justify-center',
              {
                'border-[#DC7E19] bg-primary-400/10': isDragActive,
              }
            )}
          >
            <input {...register('file')} {...getInputProps()} />
            {file?.name ? (
              <p className="font-light text-sm text-primary">{file?.name}</p>
            ) : isDragActive ? (
              <p className="font-light text-sm text-primary">
                Solte o certificado de acesso aqui...
              </p>
            ) : (
              <p className="font-light text-sm text-center text-[#555]">
                Arraste e solte aqui ou clique para selecionar um arquivo
              </p>
            )}
          </div>
        </div>
        {errors?.file?.message && <div>{errors?.file?.message as string}</div>}
        <div>
          <Input
            type="password"
            variant="outline-orange"
            {...register('password')}
            title="Senha do certificado"
            errorMensage={errors.password?.message as string}
          />
        </div>
        <Button onClick={onBack}>Voltar</Button>
        <Button className="w-full" type="submit">
          Enviar
        </Button>
      </form>
    </div>
  );
};

export default ServiceInvoiceAccessData;
