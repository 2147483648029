import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { mixed, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiExpenses } from '../../../../financial/api';
import { Button, Input, SelectComponent } from '../../../../../components';
import { ToastNotify } from '../../../../../components/Toast/toast';

const numberRegex = /^-?\d+(\.\d+)?$/;
const schema = object().shape({
  economicActivityId: mixed().required('Este campo é obrigatório'),
  sssRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  srWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  sofinsWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  sisWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  snssWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  sssWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
  ssllWithholdingRate: string()
    .matches(numberRegex, 'Esse campo deve ser um número válido')
    .required('Este campo é obrigatório'),
});
const ServiceInvoiceConfig = () => {
  const [companyConfig, setCompanyConfig] = useState<any>({});
  useState({});
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    ApiExpenses.getServiceInvoiceConfig().then((response) =>
      setCompanyConfig(response.data)
    );
  }, []);

  useEffect(() => {
    ApiExpenses.getCustomCompanyEconomicActivity().then((response) =>
      Object.entries(response.data).forEach(([key, value]) => {
        setValue(key, value);
      })
    );
  }, []);

  const cnaeOptions = useMemo(
    () =>
      companyConfig.economicActivities?.map((economicActivity: any) => ({
        label: `Cód Municipal: ${economicActivity.economicActivity.cityServiceCode} | CNAE: ${economicActivity.cnaeCode} - ${economicActivity.cnaeDescription}`,
        value: economicActivity.id,
      })),
    [companyConfig?.economicActivities]
  );
  const { notify } = ToastNotify();
  const onSubmit = async (data: any) => {
    ApiExpenses.saveCustomCompanyEconomicActivity(data).then((response) => {
      if (typeof response === 'string') {
        notify({
          message:
            'Não foi possível as configurações de nota fiscal para o usuárioF',
          type: 'Error',
        });
        return;
      }
      notify({
        message:
          'Configurações de nota fiscal para o usuário salvo com sucesso',
        type: 'Error',
      });
    });
  };
  return (
    <div className="mt-4">
      <form
        className="flex flex-col justify-center items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="self-end flex-1 w-full">
          <Controller
            name="economicActivityId"
            control={control}
            render={({ field }) => (
              <SelectComponent
                {...field}
                options={cnaeOptions}
                placeholder="Selecione o código de serviço municipal"
                value={cnaeOptions?.find((o: any) => o.value === field.value)}
                onChange={(option: { value: number }) => {
                  const selectedEconomicActivity =
                    companyConfig.economicActivities?.find(
                      (o: any) => o.id === option.value
                    );
                  field.onChange(option.value);
                  setValue(`sssRate`, selectedEconomicActivity?.issRate);
                  setValue(
                    `srWithholdingRate`,
                    selectedEconomicActivity?.irWithholdingRate
                  );
                  setValue(
                    `sofinsWithholdingRate`,
                    selectedEconomicActivity?.cofinsWithholdingRate
                  );
                  setValue(
                    `sisWithholdingRate`,
                    selectedEconomicActivity?.pisWithholdingRate
                  );
                  setValue(
                    `snssWithholdingRate`,
                    selectedEconomicActivity?.inssWithholdingRate
                  );
                  setValue(
                    `sssWithholdingRate`,
                    selectedEconomicActivity?.issWithholdingRate
                  );
                  setValue(
                    `ssllWithholdingRate`,
                    selectedEconomicActivity?.csllWithholdingRate
                  );
                }}
                errorMensage={errors?.economic_activity?.message as string}
              />
            )}
          />
        </div>

        <div className="flex gap-2 mt-4">
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`sssRate`)}
            title="Taxa do ISS (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.sssRate?.message as string}
          />
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`srWithholdingRate`)}
            title="Taxa do IR (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.srWithholdingRate?.message as string}
          />
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`sofinsWithholdingRate`)}
            title="Taxa do cofins (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.sofinsWithholdingRate?.message as string}
          />{' '}
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`sisWithholdingRate`)}
            title="Taxa PIS (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.sisWithholdingRate?.message as string}
          />{' '}
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`snssWithholdingRate`)}
            title="Taxa do inss (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.snssWithholdingRate?.message as string}
          />
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`sssWithholdingRate`)}
            title="Taxa do iss (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.sssWithholdingRate?.message as string}
          />
          <Input
            type="number"
            step={0.1}
            variant="outline-orange"
            {...register(`ssllWithholdingRate`)}
            title="Taxa do CSL (%)"
            placeholder="10 (10%)"
            errorMensage={errors?.ssllWithholdingRate?.message as string}
          />
        </div>
        <Button className="px-4 w-[200px] mt-6" type="submit">
          Salvar
        </Button>
      </form>
    </div>
  );
};

export default ServiceInvoiceConfig;
