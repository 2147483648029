/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';
import { useAtom } from 'jotai';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
} from '../../../../../components';
import InputDate from '../../../../../components/Input/inputDate';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import {
  FinancialProps,
  ItemsUpdateExpense,
  createExpense,
  updateExpense,
  updateformProps,
} from '../../../types';
import { ApiExpenses } from '../../../api';
import CreateProviderGeneralData from '../../../../providers/components/register/components/generalData';
import {
  useFetchContracts,
  useFetchContractsList,
} from '../../../../contract/hooks';
import ModalConfirm from '../../modal/modalConfirm';
import { ApiContract } from '../../../../contract/api';
import { contractProps } from '../../../../contract/types';
import ModalChangeInstallments from '../../modal/modalChangeInstallments';
import ModalConfirmReply from '../../modal/modalConfirmReply';
import { getTokenAccountInformation } from '../../../../../helpers/token.helper';
import IconDictionary from '../../../../../components/Icons/icons';
import { ClientProps } from '../../../../clients/types';
import ModalUpdateClientAdress from '../../modal/modalUpdateClientAdress';
import GeneralDataClient from '../../../../clients/components/register/components/generalData';
import { modalAccountNotActive } from '../../../../../hooks/globalAtom';

interface props extends updateformProps {
  onePage?: boolean;
  reloadProvidersList: (e: string) => void;
  providerOptions: { label: string; value: number }[];

  clientsOptions: { label: string; value: number; fullData: ClientProps }[];
  setInputClients: (e: string) => void;
  accountOptions: { label: string; value: number | undefined }[];
  notEditable: boolean;
  type: 'D' | 'R';
  isLoadingClient: boolean;
}

const CreateExpenses: React.FC<props> = ({
  defaultValue,
  type,
  accountOptions,
  providerOptions,
  isLoadingClient,
  notEditable: isEditable,
  setInputClients,
  reloadProvidersList,
  clientsOptions,
  nextPage,
  notify,
  setId,
  onePage = false,
}) => {
  const [, setShowModalAccount] = useAtom(modalAccountNotActive);
  const accountProps = getTokenAccountInformation();
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const [inputContract, setInputContract] = useState('');
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContracts, setIsLoadingContracts] = useState(false);
  const [showMessIntegration, setshowMessIntegration] = useState(false);

  const [ContractDataList, setContractDataList] = useState<{
    data: contractProps[];
    total: number;
  }>({ data: [], total: 0 });
  const [confirmReply, setConfirmReply] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalUpdateAddress, setModalUpdateAddress] = useState(false);
  const [modalItems, setModalItems] = useState(false);
  const [modalConfirmReply, setModalConfirmReply] = useState(false);
  const [clientIds, setClientIds] = useState<{
    label: string;
    value: number;
    fullData: ClientProps | null;
  } | null>(
    defaultValue && type === 'R' && defaultValue.client
      ? {
          label: defaultValue.client.name,
          value: defaultValue.client_id ?? -1,
          fullData: null,
        }
      : null
  );

  const [subItems, setSubItems] = useState<FinancialProps[]>([]);
  const [backUpValue, setBackUpValue] = useState<string[]>([]);

  const { ContractsList, isLoadingList } = useFetchContracts(
    '',
    0,
    9999999999,
    inputContract
  );
  const contractOptions = (
    clientIds ? ContractDataList.data : ContractsList.data
  ).map((item) => ({
    label: `${item.client_name} - ${item.client_document} - ${item.number}`,
    value: item.id,
    client_id: item.client_id,
  }));

  const [defaultValues, setDefaultValues] = useState<createExpense>({
    due_date:
      defaultValue?.due_date.slice(0, 10) ??
      maskHelper.formatDateYMD(new Date().toLocaleString('pt-BR').slice(0, 10)),
    form_of_payment: defaultValue?.form_of_payment ?? '',
    installment: Number(defaultValue?.installment.split('/')[1]) ?? 1,
    method: defaultValue?.method ?? '',
    obs: defaultValue?.obs ?? '',
    pla_account_id: defaultValue?.pla_account_id ?? -1,
    provider_id: defaultValue?.provider_id ?? null,
    type: defaultValue?.type ?? type,
    value: defaultValue?.value ?? '',
    contract_id: defaultValue?.contract_id ?? null,
    client_id: defaultValue?.client_id ?? null,
  });

  const schema =
    type === 'D'
      ? yup.object().shape({
          installment: yup
            .number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          method: yup.string().required('Campo obrigatório'),
          pla_account_id: yup
            .number()
            .min(0, 'Selecione uma opção')
            .required('Campo obrigatório'),
          provider_id: yup
            .number()
            .typeError('Selecione um fornecedor')
            .required('Selecione um fornecedor'),

          contract_id: yup.number().nullable().min(0, 'Selecione uma opção'),

          form_of_payment: yup.string().required('Campo obrigatório'),
          due_date: yup
            .date()
            .typeError('Campo obrigatório')
            .required('Data Pagamento é obrigatória'),

          value: yup.string().required('Campo obrigatório'),
        })
      : yup.object().shape({
          installment: yup
            .number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório'),
          method: yup.string().required('Campo obrigatório'),
          pla_account_id: yup
            .number()
            .min(0, 'Selecione uma opção')
            .required('Campo obrigatório'),
          contract_id: yup.number().nullable(),
          client_id: yup.number(),

          form_of_payment: yup.string().required('Campo obrigatório'),
          due_date: yup
            .date()
            .typeError('Campo obrigatório')
            .required('Data Pagamento é obrigatória'),

          value: yup.string().required('Campo obrigatório'),
        });

  const { setValue, handleSubmit, formState, getValues, trigger, setError } =
    useForm<createExpense>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const fetchContracts = async (clientId: number) => {
    setIsLoadingContracts(true);
    const res = await ApiContract.filterContracts({
      clientId: [clientId],
      offerId: [],
      companieUserPromoterId: [],
      order: 'created_at_desc',
      status: [],
      date_start: '',
      date_end: '',
      filter: 'all',
      skip: 0,
      take: clientIds ? 99999 : 0,
    });
    if (typeof res !== 'string') {
      setContractDataList(res);
    }
    setIsLoadingContracts(false);
  };

  const Submit = async () => {
    if (defaultValue !== null) {
      setIsLoading(true);
      let payload = {
        contract_id: defaultValues.contract_id,
        client_id: defaultValues.client_id,
        provider_id: defaultValues.provider_id
          ? defaultValues.provider_id
          : null,
        pla_account_id: defaultValues.pla_account_id,
        form_of_payment: defaultValues.form_of_payment,
        type,

        items: [
          {
            id: defaultValue.id,
            value: Number(defaultValues.value),
            due_date: maskHelper.formatDateYMD(defaultValues.due_date),
            obs: defaultValues.obs,
          },
        ],
      };
      if (defaultValue.method === 'installments') {
        payload = {
          contract_id: defaultValues.contract_id,
          client_id: defaultValues.client_id,
          provider_id: defaultValues.provider_id,
          pla_account_id: defaultValues.pla_account_id,
          form_of_payment: defaultValues.form_of_payment,
          type,

          items: confirmReply
            ? subItems.map((elem) => ({
                id: elem.id,
                value: Number(maskHelper.numberCurrencyDecimal(elem.value)),
                due_date: maskHelper.formatDateYMD(elem.due_date),
                obs: elem.obs,
              }))
            : [
                {
                  id: defaultValue.id,
                  value: Number(defaultValues.value),
                  due_date: maskHelper.formatDateYMD(defaultValues.due_date),
                  obs: defaultValues.obs,
                },
              ],
        };
      }
      const res = await ApiExpenses.updateFinancial(payload, defaultValue.id);
      if (typeof res !== 'string') {
        setIsLoading(false);
        notify({
          message: `${
            type === 'D' ? 'Despesa' : 'Receita'
          } atualizada com sucesso!`,
          type: 'Success',
        });
        nextPage();
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      let payload = {};

      if (type === 'R') {
        payload = {
          client_id: clientIds?.value,
          provider_id: null,
          contract_id: defaultValues.contract_id,
          value: defaultValues.value,
          due_date: getValues('due_date'),
          pla_account_id: defaultValues.pla_account_id,
          form_of_payment: defaultValues.form_of_payment,
          method: defaultValues.method,
          installment: defaultValues.installment,
          obs: defaultValues.obs,
          type: defaultValues.type,
        };
      } else {
        payload = {
          client_id: null,
          contract_id: defaultValues.contract_id,
          provider_id: defaultValues.provider_id,
          value: defaultValues.value,
          due_date: getValues('due_date'),
          pla_account_id: defaultValues.pla_account_id,
          form_of_payment: defaultValues.form_of_payment,
          method: defaultValues.method,
          installment: defaultValues.installment,
          obs: defaultValues.obs,
          type: defaultValues.type,
        };
      }
      const res = await ApiExpenses.createFinancial(payload);
      if (res === true || res.subscription) {
        setId(res.id);
        setIsLoading(false);
        notify({
          message: `${
            type === 'D' ? 'Despesa' : 'Receita'
          } criada com sucesso!`,
          type: 'Success',
        });
        nextPage();
      } else {
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
        setShowModalAccount(true);
      }
    }
  };

  const getFinancialsSubFin = async (fin: FinancialProps) => {
    let payload: any = {
      client_id: [],
      contract_id: [],
      type: 'Todos',
      form_of_payment: [],
      status: ['Aberta'],
      received: null,
      order: 'due_date_asc',
      pla_account_id: [],
      provider_id: [],
      status_contract: [],
      skip: 0,
      take: 999999999,
      galax_pay_id_subs: fin.galax_pay_id_subs,
    };
    if (fin.type === 'D') {
      payload = {
        client_id: [],
        contract_id: [],
        type: 'Todos',
        form_of_payment: [],
        status: ['Aberta'],
        received: null,
        order: 'due_date_asc',
        pla_account_id: [],
        provider_id: [],
        status_contract: [],
        skip: 0,
        take: 99999999999,
        group: fin.group,
      };
    }
    const res = await ApiExpenses.getFinancials(payload, 'all');
    if (typeof res !== 'string') {
      const saveValues: string[] = [];
      setSubItems(
        res.data.map((item: any) => {
          saveValues.push(maskHelper.formatMoeda(item.value));
          return {
            ...item,
            value: maskHelper.formatMoeda(defaultValues.value),
          };
        })
      );
      setBackUpValue(saveValues);
    }
  };

  const confirmSubmit = async () => {
    if (type === 'R' && !clientIds && !defaultValues.contract_id) {
      setError('client_id', {
        message: 'Escolha um cliente ou um contrato',
      });
      return;
    }

    if (
      type === 'D' &&
      defaultValues.provider_id === -1 &&
      !defaultValues.contract_id
    ) {
      setError('provider_id', {
        message: 'Escolha um fornecedor ou um contrato',
      });
      return;
    }

    if (
      type === 'R' &&
      defaultValues.form_of_payment !== 'dinheiro' &&
      defaultValues.form_of_payment !== 'ted' &&
      !moment(defaultValues.due_date).isSameOrAfter(yesterdayDate)
    ) {
      setError('due_date', {
        message: 'Data deve ser igual ou maior que hoje',
      });
      return;
    }

    if (
      defaultValues.form_of_payment === 'boleto' &&
      clientIds?.fullData?.address === null
    ) {
      setModalUpdateAddress(true);
      return;
    }

    setIsLoading(true);
    const res = await trigger();

    if (res) {
      if (defaultValue && defaultValue.method === 'installments') {
        await getFinancialsSubFin(defaultValue);
        setModalConfirmReply(true);
      } else {
        setModalConfirm(true);
      }
    }
    setIsLoading(false);
  };
  const paymentForm =
    type === 'D'
      ? [
          { value: 'boleto', label: 'Boleto' },
          { value: 'creditcard', label: 'Cartão de crédito' },
          { label: 'PIX', value: 'pix' },
          { label: 'Dinheiro', value: 'dinheiro' },
          { label: 'TED', value: 'ted' },
        ]
      : accountProps.companie.is_galaxpay
      ? [
          { value: 'boleto', label: 'Boleto Integrado' },
          { value: 'creditcard', label: 'Cartão de Crédito Integrado' },
          { label: 'PIX Integrado', value: 'pix' },
          { label: 'Dinheiro', value: 'dinheiro' },
          { label: 'TED', value: 'ted' },
        ]
      : [
          { label: 'Dinheiro', value: 'dinheiro' },
          { label: 'TED', value: 'ted' },
        ];
  const paymentMethod = [
    { label: 'À vista', value: 'in_sight' },
    { label: 'Parcelado', value: 'installments' },
  ];
  const installments = [
    { label: '1x', value: 1 },
    { label: '2x', value: 2 },
    { label: '3x', value: 3 },
    { label: '4x', value: 4 },
    { label: '5x', value: 5 },
    { label: '6x', value: 6 },
    { label: '7x', value: 7 },
    { label: '8x', value: 8 },
    { label: '9x', value: 9 },
    { label: '10x', value: 10 },
    { label: '11x', value: 11 },
    { label: '12x', value: 12 },
  ];

  useEffect(() => {
    if (
      defaultValues.form_of_payment === 'pix' ||
      defaultValues.form_of_payment === 'ted' ||
      defaultValues.form_of_payment === 'dinheiro'
    ) {
      setValue('method', 'in_sight');
      setValue('installment', 1);
      setDefaultValues({
        ...defaultValues,
        method: 'in_sight',
        installment: 1,
      });
      setDefaultValues({
        ...defaultValues,
        method: 'in_sight',
      });
    }
  }, [defaultValues.form_of_payment]);

  useEffect(() => {
    if (clientIds) fetchContracts(clientIds.value);
  }, [clientIds]);

  return (
    <>
      {clientIds?.fullData && (
        <ModalUpdateClientAdress
          client={clientIds.fullData}
          save={Submit}
          setClient={(e) => setClientIds({ ...clientIds, fullData: e })}
          setShow={setModalUpdateAddress}
          show={modalUpdateAddress}
        />
      )}
      <ModalConfirmReply
        confirmReply={confirmReply}
        setConfirmReply={setConfirmReply}
        setShow={setModalConfirmReply}
        show={modalConfirmReply}
        type={type}
        isLoading={isLoading}
        reply={(e) => {
          if (e) {
            setModalConfirmReply(false);
            setModalItems(true);
          } else {
            Submit();
          }
        }}
      />
      {defaultValue && (
        <ModalChangeInstallments
          savedValues={backUpValue}
          subItems={subItems}
          setSubItems={setSubItems}
          data={{
            value: defaultValues.value,
            clientName:
              defaultValue.client?.name ?? defaultValue.provider?.name,
            contract: defaultValue.contract?.number,
            method: defaultValue.method,
            paymentForm: defaultValue.form_of_payment,
            planName: defaultValue.plan_accounts.title,
            type,
          }}
          confirm={Submit}
          isLoading={isLoading}
          setShow={setModalItems}
          show={modalItems}
          type={type}
        />
      )}
      <ModalConfirm
        id={defaultValue?.id}
        type={type}
        confirm={Submit}
        show={modalConfirm}
        setShow={setModalConfirm}
        isLoading={isLoading}
      />
      {openCreateContact && type === 'D' && (
        <Modal
          title="Criar fornecedor"
          isOpen={openCreateContact}
          setIsOpen={setOpenCreateContact}
          size="4xlarge"
          minHeight="min-h-[700px]"
        >
          <CreateProviderGeneralData
            setProvider={() => {}}
            onePage
            id={0}
            nextPage={() => {
              setOpenCreateContact(false);
            }}
            notify={notify}
            setProviderDefaultId={(id) => {
              setDefaultValues({ ...defaultValues, provider_id: id });
              setValue('provider_id', id);
            }}
            setId={() => {
              reloadProvidersList(`${Math.random()} create`);
            }}
            defaultValue={null}
          />{' '}
        </Modal>
      )}
      {openCreateContact && type === 'R' && (
        <Modal
          title="Criar Cliente"
          isOpen={openCreateContact}
          setIsOpen={setOpenCreateContact}
          size="6xlarge"
          minHeight="min-h-[600px]"
        >
          <GeneralDataClient
            setClient={(e) => {
              setClientIds({ label: e.name, value: e.id, fullData: e });
              setValue('client_id', e.id);
              setDefaultValues({ ...defaultValues, client_id: e.id });
            }}
            onePage
            id={0}
            nextPage={() => {
              setOpenCreateContact(false);
            }}
            notify={notify}
            setId={() => {
              reloadProvidersList(`${Math.random()} create`);
            }}
            defaultValue={null}
          />{' '}
        </Modal>
      )}
      <form
        className="flex flex-col md:grid md:grid-cols-2 gap-3 mt-7 gap-y-6 xl:gap-y-4"
        onSubmit={handleSubmit(Submit)}
      >
        <SelectComponent
          hasDivision
          value={accountOptions.find(
            (item) => item.value === defaultValues.pla_account_id
          )}
          title="Plano de contas"
          maxOptionsHeight="340px"
          options={accountOptions}
          closeMenuOnSelect
          onChange={(e: any) => {
            setDefaultValues({ ...defaultValues, pla_account_id: e.value });
            setValue('pla_account_id', e.value);
          }}
          errorMensage={formState.errors.pla_account_id?.message}
        />
        {type === 'D' ? (
          <>
            <div className="relative">
              <button
                onClick={() => setOpenCreateContact(true)}
                type="button"
                className="absolute top-0 mt-1 right-0 text-xs text-primary cursor-pointer z-10"
              >
                Adicionar Novo
              </button>

              <SelectComponent
                isDisabled={isEditable}
                value={providerOptions.find(
                  (item) => item.value === defaultValues.provider_id
                )}
                title="Fornecedor"
                options={providerOptions}
                closeMenuOnSelect
                onChange={(e: any) => {
                  setValue('provider_id', e.value);
                  setDefaultValues({
                    ...defaultValues,
                    provider_id: e.value,
                  });
                }}
                errorMensage={formState.errors.provider_id?.message}
              />
            </div>
            <SelectComponent
              isDisabled={isEditable}
              value={contractOptions.find(
                (item) => item.value === defaultValues.contract_id
              )}
              isLoading={isLoadingList}
              onInputChange={setInputContract}
              title="Contrato"
              placeholder="Digite o nome ou CPF/CNPJ do cliente do contrato..."
              options={contractOptions}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('contract_id', e.value);
                setDefaultValues({
                  ...defaultValues,
                  contract_id: e.value,
                });
              }}
              errorMensage={formState.errors.contract_id?.message}
            />
          </>
        ) : (
          <>
            <div className="relative">
              <button
                onClick={() => setOpenCreateContact(true)}
                type="button"
                className="absolute top-0 mt-1 right-0 text-xs text-primary cursor-pointer z-10"
              >
                Adicionar Novo
              </button>

              <SelectComponent
                isDisabled={isEditable}
                isLoading={isLoadingClient}
                value={clientIds}
                title="Cliente"
                placeholder="Digite o nome ou CPF/CNPJ do cliente..."
                options={clientsOptions}
                closeMenuOnSelect
                onChange={(e: any) => {
                  setClientIds(e);
                  setValue('client_id', e.value);
                }}
                onInputChange={setInputClients}
                errorMensage={formState.errors.client_id?.message}
              />
            </div>
            <SelectComponent
              isDisabled={isEditable}
              value={
                isEditable && defaultValue?.contract
                  ? {
                      label: `${defaultValue?.contract?.client_name} - ${defaultValue?.contract?.number}`,
                      value: defaultValue?.contract_id,
                    }
                  : contractOptions.find(
                      (item) => item.value === defaultValues.contract_id
                    )
              }
              isLoading={isLoadingList || isLoadingContracts}
              title="Contrato"
              placeholder="Digite o nome ou CPF/CNPJ do cliente do contrato..."
              options={contractOptions}
              onInputChange={(e) =>
                !clientIds ? setInputContract(e) : undefined
              }
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('contract_id', e.value);
                setDefaultValues({
                  ...defaultValues,
                  contract_id: e.value,
                });
              }}
              errorMensage={formState.errors.contract_id?.message}
            />
          </>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div className="relative">
            {!accountProps.companie.is_galaxpay && type === 'R' && (
              <div className="absolute z-10 top-0 right-0 flex gap-2 text-xs">
                <IconDictionary
                  onMouseEnter={() => setshowMessIntegration(true)}
                  onMouseLeave={() => setshowMessIntegration(false)}
                  name="AlertCircle"
                  className="text-yellow cursor-pointer z-10"
                />
                {showMessIntegration && (
                  <p className="w-56 p-3 absolute left-0 sm:right-0 bottom-5 bg-gray-200 rounded-sm border border-solid border-gray-400">
                    Empresa não está vinculada ao galax. Os métodos de pagamento
                    que possuem integração (Boleto, PIX e Cartão) foram
                    desabilitados.
                  </p>
                )}
              </div>
            )}
            <SelectComponent
              isDisabled={isEditable}
              value={paymentForm.find(
                (item) => item.value === defaultValues.form_of_payment
              )}
              title="Formas de pagamento"
              maxOptionsHeight="200px"
              options={paymentForm}
              closeMenuOnSelect
              onChange={(e: any) => {
                if (e.value !== 'boleto' && e.value !== 'creditcard') {
                  setValue('method', 'in_sight');
                  setValue('form_of_payment', e.value);
                  setValue('installment', 1);
                  setDefaultValues({
                    ...defaultValues,
                    method: 'in_sight',
                    installment: 1,
                    form_of_payment: e.value,
                  });
                } else {
                  setDefaultValues({
                    ...defaultValues,
                    form_of_payment: e.value,
                  });
                  setValue('form_of_payment', e.value);
                }
              }}
              errorMensage={formState.errors.form_of_payment?.message}
            />
          </div>

          <SelectComponent
            isDisabled={isEditable || defaultValue !== null}
            value={paymentMethod.find(
              (item) => item.value === defaultValues.method
            )}
            title="Método"
            options={paymentMethod}
            closeMenuOnSelect
            onChange={(e: any) => {
              if (e.value === 'in_sight') {
                setValue('method', e.value);
                setValue('installment', 1);
                setDefaultValues({
                  ...defaultValues,
                  method: e.value,
                  installment: 1,
                });
              } else {
                setValue('method', e.value);
                setDefaultValues({
                  ...defaultValues,
                  method: e.value,
                });
              }
            }}
            errorMensage={formState.errors.method?.message}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <Input
            defaultValue={maskHelper.formatMoeda(defaultValues.value)}
            variant="outline-orange"
            title="Valor"
            onChange={(e) => {
              e.target.value = maskHelper.formatCurrencyInput(e.target.value);
              setDefaultValues({
                ...defaultValues,
                value: maskHelper.numberCurrencyDecimal(e.target.value),
              });
              setValue(
                'value',
                maskHelper.numberCurrencyDecimal(e.target.value)
              );
            }}
            errorMensage={formState.errors.value?.message}
          />
          <SelectComponent
            isDisabled={
              isEditable ||
              defaultValues.method === 'in_sight' ||
              defaultValue !== null
            }
            value={installments.find(
              (item) => item.value === defaultValues.installment
            )}
            title="Parcelas"
            options={installments}
            closeMenuOnSelect
            onChange={(e: any) => {
              setValue('installment', e.value);
              setDefaultValues({ ...defaultValues, installment: e.value });
            }}
            errorMensage={formState.errors.installment?.message}
          />
        </div>

        <InputDate
          defaultValue={new Date(`${defaultValues.due_date.slice(0, 10)} `)}
          title="Data Vencimento"
          onChange={(e: Date) => {
            if (e != null) {
              setDefaultValues({
                ...defaultValues,
                due_date: maskHelper.formatDateYMD(
                  e.toLocaleString('pt-BR').slice(0, 10)
                ),
              });
              setValue(
                'due_date',
                maskHelper.formatDateYMD(e.toLocaleString('pt-BR').slice(0, 10))
              );
            } else {
              setDefaultValues({
                ...defaultValues,
                due_date: maskHelper.formatDateYMD(''),
              });
              setValue('due_date', '');
            }
          }}
          errorMensage={formState.errors.due_date?.message}
        />

        <div className="col-span-2">
          <p className="text-sm mb-1">Observação</p>
          <textarea
            value={defaultValues.obs}
            onChange={(e) => {
              setValue('obs', e.target.value);
              setDefaultValues({ ...defaultValues, obs: e.target.value });
            }}
            name="textinput"
            className="text-xs border-[#ddd] disabled:bg-[#f2f2f2] focus:border-primary border border-solid w-full h-20 rounded-lg p-3 resize-none"
          />
        </div>

        <div className="col-span-2 flex justify-center mt-6">
          <Button
            type="button"
            actionType="button-loading"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={confirmSubmit}
          >
            {onePage ? 'Salvar' : 'Salvar e continuar'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreateExpenses;
