/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Button, Card, Input, SelectComponent } from '../../../components';
import { ApiExpenses } from '../api';
import { EconomicActivity } from '../../../types';

interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues: Record<string, any>;
  onBack?: () => void;
}
const fieldsToIgnore = ['economicActivity'];
const CompanyInvoiceServices: FC<FormProps> = ({
  defaultValues,
  onSubmit,
  onBack,
}) => {
  const [economicActivities, setEconomicActivities] = useState<
    EconomicActivity[]
  >([]);

  useEffect(() => {
    ApiExpenses.getPossibleCompanyEconomicActivities().then((response) =>
      setEconomicActivities(response.data)
    );
  }, []);
  const handleInternalSubmit = useCallback((data: any) => {
    const stringFields = [
      'cnaeDescription',
      'cnaeCode',
      'customCityServiceCode',
    ];
    const formattedEconomicActivities = Object.entries(data).reduce(
      (economicActivitiesReduced, [formKey, formValue]) => {
        const [economicActivityId, attribute] = formKey.split('_');
        if (!economicActivitiesReduced[economicActivityId]) {
          economicActivitiesReduced[economicActivityId] = {
            id: +economicActivityId,
          };
        }
        economicActivitiesReduced[economicActivityId][attribute] =
          stringFields.includes(attribute) ? formValue : +(formValue as string);
        formValue as string;
        return economicActivitiesReduced;
      },
      {} as Record<string, any>
    );

    onSubmit?.(Object.values(formattedEconomicActivities));
  }, []);

  const economicActivitiesOptions = useMemo(
    () =>
      economicActivities.map((e) => ({
        value: e.id,
        label: `${e.cityServiceCode} - ${e.description}`,
      })),
    [economicActivities]
  );
  const invoiceServiceSchema = useMemo(
    () =>
      object().shape(
        defaultValues?.economicActivities?.reduce(
          (schema: any, economicActivity: any) => {
            const keys = [
              `${economicActivity.id}_issRate`,
              `${economicActivity.id}_irWithholdingRate`,
              `${economicActivity.id}_cofinsWithholdingRate`,
              `${economicActivity.id}_pisWithholdingRate`,
              `${economicActivity.id}_inssWithholdingRate`,
              `${economicActivity.id}_issWithholdingRate`,
              `${economicActivity.id}_csllWithholdingRate`,
            ];
            keys.forEach((key) => {
              schema[key] = string()
                .matches(/\d+/, 'O valor deve ser um número válido')
                .required('O preenchimento desse campo é obrigatório');
            });

            return schema;
          },
          {}
        )
      ),
    [defaultValues?.economicActivities]
  );
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(invoiceServiceSchema),
    defaultValues: defaultValues.economicActivities?.reduce(
      (acc: any, economicActivity: any) => {
        Object.entries(economicActivity).forEach(([key, value]) => {
          if (fieldsToIgnore.includes(key)) return;
          acc[`${economicActivity.id}_${key}`] = value;
        });
        return acc;
      },
      {}
    ),
  });
  return (
    <Card className="flex-col">
      <h2>Serviços da empresa</h2>
      <form onSubmit={handleSubmit(handleInternalSubmit)} className="mt-5">
        {defaultValues.economicActivities.map((e: any) => (
          <div className="flex gap-2 items-end mt-6">
            <div className="self-end flex-1 min-w-[500px]">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="economicActivity"
              >
                Cnae {e?.cnaeCode} - {e?.cnaeDescription}
              </label>
              <Controller
                name={`${e.id}_cityServiceCodeId`}
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    {...field}
                    options={economicActivitiesOptions}
                    placeholder="Selecione o código de serviço municipal"
                    value={economicActivitiesOptions.find(
                      (o) => o.value === field.value
                    )}
                    onChange={(option: { value: number }) => {
                      const selectedEconomicActivity = economicActivities.find(
                        (o) => o.id === option.value
                      );
                      field.onChange(option.value);
                      setValue(
                        `${e.id}_issRate`,
                        selectedEconomicActivity?.issRate
                      );
                      setValue(
                        `${e.id}_irWithholdingRate`,
                        selectedEconomicActivity?.irWithholdingRate
                      );
                      setValue(
                        `${e.id}_cofinsWithholdingRate`,
                        selectedEconomicActivity?.cofinsWithholdingRate
                      );
                      setValue(
                        `${e.id}_pisWithholdingRate`,
                        selectedEconomicActivity?.pisWithholdingRate
                      );
                      setValue(
                        `${e.id}_inssWithholdingRate`,
                        selectedEconomicActivity?.inssWithholdingRate
                      );
                      setValue(
                        `${e.id}_issWithholdingRate`,
                        selectedEconomicActivity?.issWithholdingRate
                      );
                      setValue(
                        `${e.id}_csllWithholdingRate`,
                        selectedEconomicActivity?.csllWithholdingRate
                      );
                    }}
                    errorMensage={
                      errors?.[`economic_activity_${e.cnaeCode}`]
                        ?.message as string
                    }
                  />
                )}
              />
            </div>

            <div className="flex gap-2 ">
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_issRate`)}
                title="Taxa do ISS (%)"
                placeholder="10 (10%)"
                errorMensage={errors?.[`${e.id}_issRate`]?.message as string}
              />
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_irWithholdingRate`)}
                title="Taxa do IR (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_irWithholdingRate`]?.message as string
                }
              />
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_cofinsWithholdingRate`)}
                title="Taxa do cofins (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_cofinsWithholdingRate`]?.message as string
                }
              />{' '}
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_pisWithholdingRate`)}
                title="Taxa PIS (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_pisWithholdingRate`]?.message as string
                }
              />{' '}
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_inssWithholdingRate`)}
                title="Taxa do inss (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_inssWithholdingRate`]?.message as string
                }
              />
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_issWithholdingRate`)}
                title="Taxa do iss (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_issWithholdingRate`]?.message as string
                }
              />
              <Input
                type="number"
                step={0.1}
                variant="outline-orange"
                {...register(`${e.id}_csllWithholdingRate`)}
                title="Taxa do CSL (%)"
                placeholder="10 (10%)"
                errorMensage={
                  errors?.[`${e.id}_csllWithholdingRate`]?.message as string
                }
              />
            </div>
          </div>
        ))}
        <div>
          <Button onClick={onBack}>Voltar</Button>
          <Button type="submit">Enviar</Button>
        </div>
      </form>
    </Card>
  );
};

export default CompanyInvoiceServices;
