import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { CgMenuLeft } from 'react-icons/cg';
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline';
import { ArrowIosUpwardOutline } from '@styled-icons/evaicons-outline/ArrowIosUpwardOutline';
import { ArrowRightS } from '@styled-icons/remix-line/ArrowRightS';
import LogoBlack from '../../../../assets/png/logo-estrela.png';
import { Button } from '../../../../components';
import { logout } from '../../../login/api';
import { useFetchMenu } from '../../hooks';
import { getItem, setItem } from '../../../../helpers/storage.helper';
import { MenuSideBar, ThemeData } from '../../types';
import IconDictionary from '../../../../components/Icons/icons';
import { PagesDone } from '../../../../helpers/utils.helper';
import {
  instancesInformation,
  reloadConnection,
  reloadMenu,
} from '../../../../hooks/globalAtom';
import { ApiMenu } from '../../../admin/menu/api';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';
import { formatMenuToRoutes } from '../../utils';
import { formatInstance } from '../../../whatsapp/utils';

interface props {
  isMenuOpen: boolean;
  openMenu: React.Dispatch<React.SetStateAction<boolean>>;
  page: string;
  theme: ThemeData;
}

interface HeaderProps {
  active: boolean;
  openMenu: React.Dispatch<React.SetStateAction<boolean>>;
  theme: ThemeData;
}

interface itemsProps {
  onClick?: () => void;
  title: string;
  icon: string;
  isSelected: boolean;
  open: boolean;
  openMenu: React.Dispatch<React.SetStateAction<boolean>>;
  page: string;
  path?: string;
  group?: {
    id: number;
    title: string;
    menu: {
      id: number;
      group_id: number;
      title: string;
      route_frontend: string;
    }[];
    module_id: number;
  }[];
  setShowSubMenu: Dispatch<SetStateAction<boolean>>;
  setCurrentMenuOpen: Dispatch<SetStateAction<number>>;
  showSubMenu: boolean;
  menuId: number;
}

function MenuItem({
  title,
  icon,
  isSelected,
  group,
  page,
  path,
  open,
  menuId,
  showSubMenu,
  setShowSubMenu,
  onClick,
  setCurrentMenuOpen,
  openMenu,
}: itemsProps) {
  const colorIcon = isSelected ? '#fff' : '#8C8C8C';

  const buttonClass = isSelected
    ? `flex z-20  ${
        open ? 'justify-left' : 'justify-center'
      } items-center gap-2 px-3 2xl:px-4 w-full`
    : `bg-transparent flex z-20 ${
        open ? 'justify-left' : 'justify-center'
      } items-center gap-2 px-3 2xl:px-4 w-full`;

  const textClass = isSelected
    ? 'font-medium text-xs whitespace-nowrap'
    : 'font-medium text-xs text-left whitespace-nowrap';

  const textClassSubMenu = (selected: boolean, url: string) => {
    if (selected) {
      return 'font-medium text-xs text-primary-600 whitespace-nowrap';
    }
    return PagesDone.includes(url)
      ? 'font-medium text-xs text-left whitespace-nowrap text-gray-600'
      : 'font-medium text-xs text-left whitespace-nowrap text-[#dadada]';
  };

  return group !== undefined ? (
    <div className="w-full flex flex-col items-center mx-auto overflow-hidden">
      <Button
        variant={isSelected ? 'primary' : 'transparent'}
        className={buttonClass}
        actionType="button-full-width"
        onClick={() => {
          setShowSubMenu(!showSubMenu);
          setCurrentMenuOpen(menuId);
        }}
        onMouseEnter={() => {
          if (open) {
            setCurrentMenuOpen(menuId);
          } else {
            setShowSubMenu(true);
            setCurrentMenuOpen(menuId);
          }
        }}
      >
        <div
          className={`w-full flex ${
            open ? 'justify-between' : 'justify-center'
          }`}
        >
          <div className="flex gap-2">
            <div className="min-w-[18px]">
              <IconDictionary
                name={icon}
                color={isSelected ? '#fff' : 'var(--primary)'}
              />
            </div>
            {open && <p className={textClass}>{title}</p>}
          </div>

          {open && (
            <ArrowRightS
              size={18}
              color={colorIcon}
              onClick={() => {
                setShowSubMenu(true);
                setCurrentMenuOpen(menuId);
              }}
            />
          )}
        </div>
      </Button>

      {showSubMenu && (
        <>
          <button
            type="button"
            className="cursor-default h-screen w-screen absolute top-0 left-0 z-10"
            onClick={() => {
              setShowSubMenu(false);
              openMenu(false);
            }}
          >
            {' '}
          </button>
          <div
            className={classNames([
              'absolute',
              open ? 'left-52' : 'left-16',
              'bg-white',
              'z-50',
              'w-fit max-w-5xl',
              'border-l-[5px]',
              'drop-shadow-3xl',
              'border-solid',
              'border-primary',
              'rounded',
              'ml-10',
              'px-6',
              'py-6',
              'gap-3',
              'min-h-[100px]',
              'slide 2s linear infinite',
            ])}
          >
            <div className="w-3 h-6 overflow-hidden flex rotate-180 -ml-[42px] -mb-2 -mt-4">
              <div className="w-4 h-4 border-l-4 border-t-4 transform my-auto rotate-45 -ml-2 bg-primary" />
            </div>
            <div className="flex max-w-3xl min-w-[400px]">
              {group !== undefined &&
                group.map((item) => (
                  <div
                    key={`${item.id}_group_menu`}
                    className="flex flex-col mr-10"
                  >
                    <strong className="text-sm mb-4">{item.title}</strong>
                    <div className="flex flex-col gap-3">
                      {item.menu.map((currentMenu) => (
                        <Link
                          key={`${currentMenu.id}_current_menu`}
                          to={`/${currentMenu.route_frontend}`}
                          className="flex items-center w-fit gap-2 transition ease-in-out delay-75 hover:-translate-y-0.5"
                        >
                          <IconDictionary
                            name={currentMenu.title}
                            color={
                              page === currentMenu.route_frontend
                                ? '#000'
                                : PagesDone.includes(currentMenu.route_frontend)
                                ? 'var(--primary)'
                                : '#dadada'
                            }
                          />
                          <p
                            className={textClassSubMenu(
                              page === currentMenu.route_frontend,
                              currentMenu.route_frontend
                            )}
                          >
                            {currentMenu.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  ) : (
    <Link
      to={`/${path}`}
      className="flex justify-center w-full mx-auto overflow-hidden"
      onClick={() => setShowSubMenu(false)}
    >
      <Button
        onClick={onClick}
        variant={isSelected ? 'primary' : 'transparent'}
        className={buttonClass}
        actionType="button-full-width"
      >
        <div className="min-w-[18px]">
          <IconDictionary
            name={icon}
            color={isSelected ? '#fff' : 'var(--primary)'}
          />
        </div>

        {open && <p className={textClass}>{title}</p>}
      </Button>
    </Link>
  );
}

export const Menu: React.FC<props> = ({
  isMenuOpen,
  openMenu,
  page,
  theme,
}: props) => {
  const accountProps = getTokenAccountInformation();
  const [keyMenu, setKeyMenu] = useAtom(reloadMenu);
  const [, setIntancesNumber] = useAtom(instancesInformation);
  const { MenusList } = useFetchMenu(keyMenu);
  const [currentMenuOpen, setCurrentMenuOpen] = useState(-1);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [, setLostConnection] = useAtom(reloadConnection);

  const handleLogout = () => {
    logout();
  };

  function isSelectedGroup(e: { menu: { route_frontend: string }[] }[]) {
    for (let i = 0; i < e.length; i++) {
      for (let j = 0; j < e[i].menu.length; j++) {
        if (page === e[i].menu[j].route_frontend) {
          return true;
        }
      }
    }
    return false;
  }

  const reloadMenus = async () => {
    const responseMenus = await ApiMenu.getAllMenusByUser(accountProps.id);
    if (responseMenus === 'Sem conexão') {
      setLostConnection(true);
      return;
    }

    if (typeof responseMenus !== 'string' && responseMenus !== MenusList) {
      setItem('menu', responseMenus);
      const routesUser = formatMenuToRoutes(responseMenus);
      setItem('routes_user', routesUser);
      setKeyMenu(`${Math.random()}`);
    }
  };

  const reloadInstances = async () => {
    const data = await formatInstance();
    if (!data) {
      return;
    }
    if (data && data.qty) {
      setIntancesNumber(data);
    }
  };

  useEffect(() => {
    setShowSubMenu(false);
    openMenu(false);
    reloadMenus();
    reloadInstances();
  }, [page]);

  return (
    <div
      className={classNames([
        'min-h-full bg-white hidden lg:flex flex-col pt-5 items-center justify-between px-5 pb-8 fixed z-40 transition-all duration-300 ease-in-out  shadow-neutral-300 shadow-lg',
        isMenuOpen ? 'w-[235px]' : 'w-[90px]',
      ])}
      onMouseEnter={() => openMenu(true)}
      onMouseLeave={() => openMenu(false)}
    >
      <div className="flex flex-col items-center gap-2 w-full">
        <div className="flex items-center mb-3">
          <div
            className={classNames([
              'flex flex-col items-center justify-center gap-1.5 w-24 bg-secondary h-24 rounded-full border border-primary border-solid mb-2 overflow-hidden',
              !isMenuOpen
                ? 'transition ease-in-out delay-150 scale-75 duration-200 forwards'
                : 'transition ease-in-out delay-150 scale-100 duration-200 forwards',
            ])}
          >
            <img
              src={theme.logo}
              alt="logo-black"
              className={`w-full object-center overflow-hidden object-contain ${
                !theme.hasCustomImage && 'p-4'
              }`}
              id="logo-black"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = LogoBlack;
              }}
              onLoad={(e: any) => {
                if (e.target.src !== LogoBlack) {
                  e.target.style.backgroundColor = 'transparent';
                }
              }}
            />
          </div>
        </div>

        {MenusList.map((item) => (
          <MenuItem
            menuId={item.id}
            setShowSubMenu={setShowSubMenu}
            showSubMenu={currentMenuOpen === item.id && showSubMenu}
            setCurrentMenuOpen={setCurrentMenuOpen}
            openMenu={openMenu}
            key={item.id}
            open={isMenuOpen}
            title={item.title}
            isSelected={
              item.group !== undefined
                ? isSelectedGroup(item.group)
                : page === item.route_frontend
            }
            icon={item.route_frontend ?? item.title}
            group={item.group}
            page={page}
            path={item.route_frontend}
          />
        ))}
        {!accountProps.companie?.is_wireframe && (
          <MenuItem
            menuId={-1}
            setShowSubMenu={setShowSubMenu}
            showSubMenu={false}
            setCurrentMenuOpen={setCurrentMenuOpen}
            open={isMenuOpen}
            title="Desconectar"
            isSelected={false}
            openMenu={openMenu}
            icon="desconectar"
            path="logout"
            page={page}
            onClick={handleLogout}
          />
        )}
      </div>
    </div>
  );
};

interface subMenuProps {
  group?: {
    id: number;
    title: string;
    menu: {
      id: number;
      group_id: number;
      title: string;
      route_frontend: string;
    }[];
    module_id: number;
  }[];
  title: string;
  /* eslint-disable no-unused-vars */
  closeMenu: (e: boolean) => void;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  setCurrentMenuOpen: Dispatch<SetStateAction<number>>;
  showMenu: boolean;
  menuId: number;
}

function SubMenuHeaderMobile({
  group,
  title,
  closeMenu,
  menuId,
  setCurrentMenuOpen,
  setShowMenu,
  showMenu,
}: subMenuProps) {
  const [idGrupSelected, setIdGroupIdSelected] = useState(-1);
  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          if (!showSubMenu) {
            setCurrentMenuOpen(menuId);
          }
          setShowMenu(!showMenu);
        }}
        className="flex flex-row w-full items-center mt-3 gap-5 text-white"
        type="button"
      >
        <IconDictionary name={title} />
        <p className="text-sm">{title}</p>
        {showMenu ? (
          <ArrowIosUpwardOutline
            size={16}
            color="#fff"
            onClick={() => {
              setCurrentMenuOpen(-1);
              setShowMenu(false);
            }}
          />
        ) : (
          <ArrowIosDownwardOutline
            size={16}
            color="#fff"
            onClick={() => {
              setCurrentMenuOpen(menuId);
              setShowMenu(true);
            }}
          />
        )}
      </button>
      <div className="w-full flex flex-wrap gap-2 ml-8 mt-3 mb-2">
        {showMenu &&
          group?.map((item) => (
            <div className="w-fit flex flex-col" key={item.id + item.module_id}>
              <button
                onClick={() => {
                  setShowSubMenu(!showSubMenu);
                  setIdGroupIdSelected(item.id);
                }}
                type="button"
                className={classNames([
                  'w-fit gap-2 p-1.5 rounded-md min-w-fit flex justify-between items-center text-sm',
                  idGrupSelected === item.id
                    ? 'bg-primary/80 text-white'
                    : 'bg-gray-300 text-gray-600',
                ])}
              >
                <IconDictionary name={item.title} />
                <p>{item.title}</p>
              </button>
            </div>
          ))}
      </div>
      {showMenu && (
        <div className="flex flex-col border-l border-white border-solid ml-10 mt-2 pl-3 gap-2 mb-3">
          {group
            ?.find((item) => item.id === idGrupSelected)
            ?.menu.map((submenus) => (
              <Link
                key={`${submenus.id}_submenus`}
                to={`/${submenus.route_frontend}`}
                className="w-fit flex gap-3 text-white items-center"
              >
                <IconDictionary name={submenus.title} />
                <p>{submenus.title}</p>
              </Link>
            ))}
        </div>
      )}
    </>
  );
}

export function HeaderMobile({ active, openMenu, theme }: HeaderProps) {
  const accountProps = getTokenAccountInformation();
  const [showMenus, setShowMenus] = useState(false);
  const [currentMenuOpen, setCurrentMenuOpen] = useState(-1);
  const MenusList: MenuSideBar[] = getItem('menu');

  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/logout');
  };
  useEffect(() => {
    openMenu(false);
    setShowMenus(false);
  }, [window.location.pathname]);

  return !active ? (
    <div className="fixed z-50 top-0 left-0 w-full h-10 px-2 py-1 flex justify-between lg:hidden bg-primary">
      <CgMenuLeft
        color="#ffff"
        size={32}
        onClick={() => openMenu(true)}
        className="cursor-pointer"
      />
      <img
        src={theme.logo_mobile}
        alt="logo"
        className="cursor-pointer max-w-[179px] h-full object-center overflow-hidden object-contain"
        id="logo-header-mobile"
      />
    </div>
  ) : (
    <div className="bg-black/25 py-6 px-8 backdrop-blur-lg w-screen h-screen fixed top-0 left-0 z-50 lg:hidden overflow-y-auto">
      <div className="flex flex-row">
        <CloseOutline
          size={30}
          color="#fff"
          onClick={() => openMenu(false)}
          className="absolute t-0 l-0 cursor-pointer"
        />
        <div className="flex w-full justify-center h-20">
          <img
            alt="logo-leadsim"
            src={theme.logo_mobile}
            className="cursor-pointer max-w-[179px] h-full object-center overflow-hidden object-contain rounded-full"
            id="logo-header-mobile"
          />
        </div>
      </div>
      <div className="mt-12">
        <div className="flex flex-col pl-5">
          {MenusList.map((item) =>
            item.group ? (
              <div key={`${item.id}_item_group`}>
                <SubMenuHeaderMobile
                  closeMenu={openMenu}
                  group={item.group}
                  title={item.title}
                  menuId={item.id}
                  setShowMenu={setShowMenus}
                  showMenu={currentMenuOpen === item.id && showMenus}
                  setCurrentMenuOpen={setCurrentMenuOpen}
                />
                <hr className="w-full bg-white h-px" />
              </div>
            ) : (
              <div
                className="flex flex-col w-full"
                key={`${item.id}_item_group_2`}
              >
                <Link
                  to={`/${item.route_frontend}`}
                  key={item.id}
                  onClick={() => {
                    openMenu(false);
                    setCurrentMenuOpen(item.id);
                  }}
                >
                  <div className="flex flex-row w-full items-center my-6 gap-5 text-white">
                    <IconDictionary name={item.title} />
                    <p className="text-sm">{item.title}</p>
                  </div>
                </Link>
                <hr className="w-full bg-white h-px" />
              </div>
            )
          )}
          {!accountProps.companie?.is_wireframe && (
            <div className="flex flex-col w-full">
              <button type="button" onClick={handleLogout}>
                <div className="flex flex-row w-full items-center my-6 gap-5 text-white">
                  <IconDictionary name="desconectar" />
                  <p className="text-sm">Desconectar</p>
                </div>
              </button>

              <hr className="w-full bg-white h-px" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
